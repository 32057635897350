export const medicalRoutes = {
  top: () => `/medical`,

  fitnessUserList: ({ medicalTenantID }: { medicalTenantID: string }) =>
    `/medical/${medicalTenantID}/fitness-user`,
  fitnessUserDetail: ({
    medicalTenantID,
    fitnessUserID,
  }: {
    medicalTenantID: string;
    fitnessUserID: string;
  }) => `/medical/${medicalTenantID}/fitness-user/${fitnessUserID}`,
  // staff
  medicalStaffList: ({ medicalTenantID }: { medicalTenantID: string }) =>
    `/medical/${medicalTenantID}/medical-staff`,
  medicalStaffNew: ({ medicalTenantID }: { medicalTenantID: string }) =>
    `/medical/${medicalTenantID}/medical-staff-new`,

  // user
  userInitialPassword: () => `/user/initial-password`,

  // auth
  authLogin: () => `/auth/login`,
  authConfirm: () => `/auth/confirm`,
  authSendPasswordResetEmail: () => `/auth/send-reset-password-email`,
  authSendPasswordResetEmailSuccess: () =>
    `/auth/send-reset-password-email-success`,
};

export const medicalActions = {
  deleteMedicalStaff: ({ medicalTenantID }: { medicalTenantID: string }) =>
    `/medical/${medicalTenantID}/medical-staff-delete`,
  reinviteMedicalStaff: ({ medicalTenantID }: { medicalTenantID: string }) =>
    `/medical/${medicalTenantID}/medical-staff-reinvite`,
  // updateFitnessUser: ({
  //   fitnessTenantID,
  //   fitnessUserID,
  // }: {
  //   fitnessTenantID: string;
  //   fitnessUserID: string;
  // }) => `/fitness/${fitnessTenantID}/fitness-user/${fitnessUserID}/update`,
  // deleteFitnessUser: ({
  //   fitnessTenantID,
  //   fitnessUserID,
  // }: {
  //   fitnessTenantID: string;
  //   fitnessUserID: string;
  // }) => `/fitness/${fitnessTenantID}/fitness-user/${fitnessUserID}/delete`,
  // searchFitnessTenants: ({ fitnessTenantID }: { fitnessTenantID: string }) =>
  //   `/fitness/${fitnessTenantID}/search-medical-tenants`,
  // searchRelatedMedicalTenants: ({
  //   fitnessTenantID,
  // }: {
  //   fitnessTenantID: string;
  // }) => `/fitness/${fitnessTenantID}/search-related-medical-tenants`,
  // upsertMedicalPrescription: ({
  //   fitnessTenantID,
  //   fitnessUserID,
  // }: {
  //   fitnessTenantID: string;
  //   fitnessUserID: string;
  // }) =>
  //   `/fitness/${fitnessTenantID}/fitness-user/${fitnessUserID}/upsert-medical-prescription`,

  // user
  userSendPasswordResetEmail: () => `/user/send-reset-password-email`,
  userLogout: () => `/user/logout`,

  // auth
  authSendPasswordResetEmail: () => `/auth/send-reset-password-email`,
};
